import { useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { ConnectionContext } from '../context/ConnectionContext/provider';
import ErrorPage from '../pages/Utils/ErrorPage';
import { useDispatch, useSelector } from 'react-redux';
import Header from './components/Header';
import Breadcrumbs from './components/Breadcrumbs';
import Footer from './components/Footer';
import { clearErrors, getProjects } from '../services/redux/projects/actions';
import styles from './index.module.scss';
import { jwtDecode } from 'jwt-decode';
import { getUserMe } from '../services/redux/users/actions';
import { getLogout } from '../services/redux/auth/actions';

const { content } = styles;

const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoggedIn, me } = useSelector(({ auth }) => auth);
  const { loading, setLoading, error, createError } =
    useContext(ConnectionContext);

  useEffect(() => {
    let user;
    if (!me) {
      user = localStorage.getItem('token')
        ? jwtDecode(localStorage.getItem('token'))
        : undefined;
      if (user) {
        dispatch(getUserMe(user.id));
      } else {
        dispatch(getLogout());
      }
    }
  }, [dispatch, me]);

  useEffect(() => {
    if (isLoggedIn) {
      setLoading(true);
      try {
        dispatch(getProjects({ size: 100, page: 1 }));
        dispatch(clearErrors(null));
        setLoading(false);
      } catch (error) {
        createError({
          status: error.status,
          statusText: error.statusText,
          errorType: 'GET_PROJECTS_ERROR',
        });
      }
    }
  }, [createError, dispatch, isLoggedIn, setLoading]);

  useEffect(() => {
    setLoading(false);
  }, [location, setLoading]);

  return isLoggedIn ? (
    <>
      <Header />
      <Breadcrumbs />
      <div className={content}>
        {error ? (
          <ErrorPage errorMessages={error} />
        ) : (
          <>
            {loading && <Spinner loading />}
            <Outlet />
          </>
        )}
      </div>
      <Footer />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default Layout;
