import { call, put } from 'redux-saga/effects';

function* callToAPI({
  apiCall,
  successResponseCode,
  errorType,
  callback,
  payload = null,
}) {
  try {
    const response = yield call(apiCall, payload);
    if (response.status === 401 && errorType !== 'GET_LOGIN_ERROR') {
      yield put({ type: 'GET_LOGOUT_REQUEST' });
    } else {
      if (response.status === successResponseCode) {
        yield callback(response);
      } else {
        yield put({ type: errorType, payload: response });
        yield put({
          type: 'CREATE_ERRORS',
          payload: {
            statusText: response.data.errors || response.statusText,
            status: response.status,
            errorType: errorType,
            message: response.data.message,
          },
        });
      }
    }
  } catch (error) {
    yield put({
      type: 'CREATE_ERRORS',
      payload: {
        statusText: 'Bad Gateway',
        status: 502,
        errorType: errorType,
      },
    });
  }
}

function* callToAPI_Cache({
  apiCall,
  successResponseCode,
  errorType,
  callback,
  callbackOffline,
  payload = null,
}) {
  try {
    const response = yield call(apiCall, payload);
    if (response.status === successResponseCode) {
      yield callback(response);
    } else {
      yield put({ type: errorType, payload: response });
      yield put({
        type: 'CREATE_ERRORS',
        payload: {
          statusText: response.data.errors || response.statusText,
          status: response.status,
          errorType: errorType,
        },
      });
    }
  } catch (error) {
    if (navigator.onLine) {
      yield put({
        type: 'CREATE_ERRORS',
        payload: {
          statusText: 'Bad Gateway',
          status: 502,
          errorType: errorType,
        },
      });
    } else {
      yield callbackOffline();
    }
  }
}

export { callToAPI, callToAPI_Cache };
