import { combineReducers } from 'redux';
import auth from './auth/reducer';
import online from './ocr/preDNOnline/reducer';
import offline from './ocr/preDNOffline/reducer';
import thermalControlDevices from './strength/thermalControl/devices/reducer';
import thermalControlMixtures from './strength/thermalControl/mixtures/reducer';
import thermalControlMonitorings from './strength/thermalControl/monitorings/reducer';
import hoodedControlDevices from './strength/hoodedControl/devices/reducer';
import hoodedControlMonitorings from './strength/hoodedControl/monitorings/reducer';
import projects from './projects/reducer';
import users from './users/reducer';
import { GET_LOGOUT_REQUEST } from './auth/types';

const appReducer = combineReducers({
  projects,
  online,
  auth,
  offline,
  thermalControlDevices,
  thermalControlMixtures,
  thermalControlMonitorings,
  hoodedControlDevices,
  hoodedControlMonitorings,
  users,
});

const reducers = (state, action) => {
  if (action.type === GET_LOGOUT_REQUEST) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default reducers;
