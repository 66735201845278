import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { getUsers, deleteUser } from '../../../services/redux/users/actions';
import { COLUMNS_HEADERS } from './columns';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import RemoveModal from '../../../components/RemoveModal';
import AccionaList from '../../../components/AccionaList';
import AccionaListButton from '../../../components/AccionaList/AccionaListButton';
import AccionaButton from '../../../components/AccionaButton';

const List = ({ getItems }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState();

  const onSingleDeleteButtonClick = (user) => {
    setSelectedRows([user]);
    setShowRemoveModal(true);
  };

  const handleOnBulkDeleting = () => {
    setShowRemoveModal(false);
    dispatch(deleteUser(selectedRows[0]));
    setSelectedRows([]);
  };

  const handleOnClickEdit = (user) => {
    navigate('edit/'.concat(user.id));
  };

  return (
    <>
      <div className="wrapper">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          }}
        >
          <AccionaButton
            id="createBtn"
            onClick={() => {
              navigate('create');
            }}
            msg={intl.formatMessage({ id: 'button.add' })}
            classIcon="icon fas fa-plus"
            type="white"
          />
        </div>
        <AccionaList
          getItems={getUsers}
          headingWidth="150px"
          entity="users"
          columns={COLUMNS_HEADERS}
          actions={(user) => {
            return (
              <>
                <AccionaListButton
                  onClick={() => handleOnClickEdit(user)}
                  type="edit"
                  toolTip={intl.formatMessage({
                    id: 'editAction.user',
                  })}
                />
                <AccionaListButton
                  onClick={() => onSingleDeleteButtonClick(user)}
                  type="delete"
                  toolTip={intl.formatMessage({
                    id: 'deleteAction.users',
                  })}
                  disabled={user.id === 2}
                />
              </>
            );
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <AccionaButton
            msg={intl.formatMessage({ id: 'back' })}
            onClick={() => navigate(-1)}
            classIcon="icon fas fa-chevron-left"
            style={{ float: 'right', marginTop: '10px', marginRight: '0px' }}
          />
        </div>
      </div>
      <RemoveModal
        entity="users"
        selectedRows={selectedRows}
        msg={intl.formatMessage(
          { id: 'delete.users' },
          { total: selectedRows.length },
        )}
        onOk={handleOnBulkDeleting}
        onCancel={() => setShowRemoveModal((prev) => !prev)}
        hidden={!showRemoveModal}
      />
    </>
  );
};

List.propTypes = {
  getItems: PropTypes.func.isRequired,
};

export default connect(() => ({}), { getItems: getUsers })(List);
