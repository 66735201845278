import { EDIT_USER_ME_SUCCESS, GET_USER_ME_SUCCESS } from '../users/types';
import {
  GET_LOGIN_ERROR,
  GET_LOGIN_REQUEST,
  GET_LOGIN_SUCCESS,
  GET_LOGOUT_REQUEST,
  SET_LOGIN_ERROR,
} from './types';

const initialState = {
  isLoggedIn: localStorage.getItem('token'),
  loading: false,
  token: localStorage.getItem('token'),
  loginError: false,
  me: undefined,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_LOGIN_REQUEST:
      return { ...state, loading: true, loginError: false };

    case GET_LOGOUT_REQUEST:
      localStorage.clear();
      return { ...state, loading: false, isLoggedIn: false, loginError: false };

    case EDIT_USER_ME_SUCCESS:
    case GET_USER_ME_SUCCESS: {
      return { ...state, me: payload };
    }

    case GET_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token: payload.token,
        loginError: false,
        me: payload.user,
      };

    case GET_LOGIN_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        token: undefined,
        loginError: true,
      };
    case SET_LOGIN_ERROR:
      return { ...state, loginError: false };

    case 'CLEAR_ERRORS':
      return { ...state, loginError: false };

    default:
      return state;
  }
};

export default reducer;
