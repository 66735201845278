import { Navigate, Outlet, useParams, useLocation } from 'react-router-dom';
import { getProject } from '../../utils/utils';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const ProtectedComponent = ({ roles }) => {
  const location = useLocation();
  const { me } = useSelector(({ auth }) => auth);
  const isProjectsPage = location.pathname.includes('projects');
  const isUsersPage = location.pathname.includes('users');
  const isOCRRoute = location.pathname.includes('/ocr');
  const isAllowedByRole = !roles
    ? true
    : roles && me?.role
      ? roles.includes(me?.role)
      : true;
  const { id } = useParams();
  const project = getProject(id);

  return (isProjectsPage || isUsersPage || project || isOCRRoute) &&
    isAllowedByRole ? (
    <Outlet context={{ project }} />
  ) : (
    <Navigate to="/login" />
  );
};

ProtectedComponent.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
};

ProtectedComponent.defaultProps = {
  roles: undefined,
};

export default ProtectedComponent;
