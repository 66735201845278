import { Select } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

const { select, popup } = styles;

const AntdSelect = ({ size, ...props }) => {
  return (
    <Select
      className={select}
      size={size === 'xLarge' ? 'large' : size}
      suffixIcon={<i className="fas fa-chevron-down" />}
      popupClassName={popup}
      {...props}
    />
  );
};

AntdSelect.propTypes = {
  size: PropTypes.oneOf(['large', 'small', 'middle', 'xLarge']),
};

AntdSelect.defaultProps = {
  size: 'middle',
};

export default AntdSelect;
