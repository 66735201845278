import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import MaturityMethods from '../../../../../../../../../constants/maturityMethods';

const { sensors, minmaxTemperatureWrapper, sensor } = styles;

const MonitoringData = ({ monitoring }) => {
  const intl = useIntl();

  const getColumnMethod = () => {
    return (
      <div>
        {intl.formatMessage({
          id: `strength.thermalcontrol.monitorings.view.${
            monitoring.mixture_data.maturity_method ===
            MaturityMethods.NURSE_SAUL
              ? 'maturity'
              : 'equivalentAge'
          }`,
        })}{' '}
        <span>
          {`(${intl.formatMessage({
            id: `maturityMethod.${monitoring.mixture_data.maturity_method}`,
          })})`}
        </span>
        :
      </div>
    );
  };
  return (
    <>
      <div className={sensors}>
        {[...Array(5)].map((_, index) => {
          const channelData = monitoring.data?.[`ch${index + 1}`];
          const channel = monitoring[`ch${index + 1}`];
          return (
            <div className={sensor} key={`Ch${index + 1}`}>
              <div>
                <p>
                  {intl.formatMessage(
                    {
                      id: 'strength.thermalcontrol.monitorings.create.channel',
                    },
                    { number: index + 1 },
                  )}
                </p>
              </div>
              <div>
                <p>
                  {intl.formatMessage({
                    id: 'strength.thermalcontrol.monitorings.view.description',
                  })}
                  :
                </p>
                <p>
                  {index === 4
                    ? intl.formatMessage({ id: 'environment' })
                    : channel}
                </p>
              </div>
              <div>
                <p>
                  {intl.formatMessage({
                    id: 'strength.thermalcontrol.monitorings.view.temperature',
                  })}
                  :
                </p>
                <p>
                  {channelData?.temperature?.toFixed(1) &&
                    `${channelData?.temperature?.toFixed(1)}ºC`}
                </p>
              </div>
              <div>
                <p>
                  {intl.formatMessage({
                    id: 'strength.thermalcontrol.monitorings.view.max.min.temperature',
                  })}
                  :
                </p>
                <p className={minmaxTemperatureWrapper}>
                  {channelData?.max && (
                    <div>{channelData?.max.toFixed(1)}ºC</div>
                  )}
                  {channelData?.max && channelData?.min && '/'}
                  {channelData?.min && (
                    <div>{channelData?.min.toFixed(1)}ºC</div>
                  )}
                </p>
              </div>
              {monitoring.mixture && (
                <>
                  <div>
                    <p>{getColumnMethod()}</p>
                    <p>
                      {channelData?.maturity && index !== 4
                        ? `${channelData?.maturity?.toFixed(1)}${
                            monitoring.mixture_data.maturity_method ===
                            MaturityMethods.NURSE_SAUL
                              ? 'ºC-h'
                              : ` d`
                          }`
                        : ''}
                    </p>
                  </div>
                  <div>
                    <p>
                      {intl.formatMessage({
                        id: 'strength.thermalcontrol.monitorings.view.strength',
                      })}
                      :
                    </p>
                    <p>
                      {channelData?.resistance && index !== 4
                        ? `${channelData?.resistance?.toFixed(1)} Mpa`
                        : ''}
                    </p>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

MonitoringData.propTypes = {
  monitoring: PropTypes.shape({
    mixture_data: PropTypes.shape({
      maturity_method: PropTypes.string,
    }),
    data: PropTypes.object,
    mixture: PropTypes.number,
  }).isRequired,
};

export default MonitoringData;
