import { PropTypes } from 'prop-types';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getMonitoringData } from '../../../../../../../services/api/thermalControl/monitoring';
import style from './index.module.scss';
import { useIntl } from 'react-intl';
import AccionaButton from '../../../../../../../components/AccionaButton';
import dayjs from 'dayjs';
import MaturityMethods from '../../../../../../../constants/maturityMethods';
import TemperatureChart from './components/TemperaturesChart';
import MaturityChart from './components/MaturityChart';
import ChartWrapper from './components/ChartWrapper';
import MonitoringData from './components/MonitoringData';
import GradientChart from './components/GradientChart';
import { useSelector } from 'react-redux';
import BatteryStatus from '../../../../../../../components/BatteryStatus';
import ResistanceChart from './components/ResistanceChart';
import { ConnectionContext } from '../../../../../../../context/ConnectionContext/provider';
import AccionaSelect from '../../../../../../../components/AccionaSelect';

const { wrapper, header, footer, spin, tabs } = style;

const Spinner = () => {
  return (
    <div className={spin}>
      <img
        src="/Static/img/ajax-loader.svg"
        height="64"
        width="64"
        alt="Loading"
      />
    </div>
  );
};

const getMinDate = (_monitoring) => {
  const chTimestamps = [
    _monitoring.ch1.ts,
    _monitoring.ch2.ts,
    _monitoring.ch3.ts,
    _monitoring.ch4.ts,
    _monitoring.ch5.ts,
  ];
  const validDates = chTimestamps
    .filter((ts) => ts !== null)
    .map((ts) => dayjs(ts));
  if (validDates.length) {
    return dayjs.min(validDates);
  } else {
    return dayjs().subtract(1, 'days');
  }
};

const View = ({ monitoringId, onCancel }) => {
  const intl = useIntl();
  const noDataFoundTimeOut = useRef();
  const realTimeTimeOut = useRef();
  const { createError } = useContext(ConnectionContext);
  const mainMonitoring = useSelector(({ thermalControlMonitorings }) =>
    thermalControlMonitorings.list.find(
      (monitoring) => monitoring.id === monitoringId,
    ),
  );
  const [monitoring, setMonitoring] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('data');
  const [range, setRange] = useState([dayjs().subtract('1', 'days'), dayjs()]);

  const availableChannels = useMemo(() => {
    const result = [];
    Object.keys(monitoring).forEach((key) => {
      if (key.includes('enabled') && monitoring[key] === true) {
        const ch = key.split('_enabled')[0];
        result.push({ channel: ch, description: monitoring[ch] });
      }
    });
    return result;
  }, [monitoring]);

  const getData = useCallback(
    async (id) => {
      try {
        const { data } = await getMonitoringData(id);
        setMonitoring(data);
        setLoading(false);
      } catch (e) {
        if (e.response?.status === 425) {
          if (noDataFoundTimeOut.current) {
            clearTimeout(noDataFoundTimeOut.current);
            noDataFoundTimeOut.current = undefined;
          }
          noDataFoundTimeOut.current = setTimeout(() => getData(id), 10000);
        } else {
          createError(e.response);
          setLoading(false);
        }
      }
    },
    [createError],
  );
  useEffect(() => {
    if (mainMonitoring) {
      setRange((prev) => [
        getMinDate(mainMonitoring),
        mainMonitoring.finished && monitoring.data?.lastTs
          ? dayjs(monitoring.data?.lastTs)
          : prev[1],
      ]);
    }
  }, [mainMonitoring, monitoring.data?.lastTs]);

  useEffect(() => {
    if (monitoringId) {
      setLoading(true);
      getData(monitoringId);
    }
    return () => clearTimeout(noDataFoundTimeOut.current);
  }, [monitoringId, getData]);

  useEffect(() => {
    if (selectedTab === 'data' && !monitoring.finished) {
      realTimeTimeOut.current = setTimeout(() => {
        getData(monitoringId);
      }, 60000);
    }

    return () => {
      clearTimeout(realTimeTimeOut.current);
    };
  }, [monitoringId, getData, selectedTab, monitoring.finished]);

  const getComponent = () => {
    if (loading) {
      return <Spinner />;
    }

    if (selectedTab === 'data') {
      return <MonitoringData monitoring={monitoring} />;
    }

    if (selectedTab === 'temperatureChart') {
      return (
        <ChartWrapper
          range={range}
          onChange={setRange}
          monitoringId={monitoringId}
          monitoringName={mainMonitoring.description}
          name="temperature"
          render={({ data, loading, range }) => {
            return (
              <TemperatureChart
                data={data}
                loading={loading}
                monitoring={monitoring}
                range={range}
              />
            );
          }}
        />
      );
    }

    if (selectedTab === 'maturityChart') {
      return (
        <ChartWrapper
          range={range}
          onChange={setRange}
          monitoringId={monitoringId}
          monitoringName={mainMonitoring.description}
          name="maturity"
          render={({ data, loading, range }) => {
            return (
              <MaturityChart
                data={data}
                loading={loading}
                YUnit="ºC-h"
                monitoring={monitoring}
                name="maturity"
                range={range}
              />
            );
          }}
        />
      );
    }
    if (selectedTab === 'equivalentAgeChart') {
      return (
        <ChartWrapper
          range={range}
          onChange={setRange}
          monitoringId={monitoringId}
          monitoringName={mainMonitoring.description}
          name="equivalent_age"
          render={({ data, loading, range }) => {
            return (
              <MaturityChart
                data={data}
                loading={loading}
                name="equivalent_age"
                YUnit={` d`}
                monitoring={monitoring}
                range={range}
              />
            );
          }}
        />
      );
    }
    if (selectedTab === 'gradientChart') {
      return (
        <ChartWrapper
          range={range}
          onChange={setRange}
          monitoringId={monitoringId}
          monitoringName={mainMonitoring.description}
          availableChannels={availableChannels}
          name="gradient"
          render={({ data, loading, range, channelRange }) => {
            return (
              <GradientChart
                data={data}
                loading={loading}
                name="equivalent_age"
                YUnit={` d`}
                monitoring={monitoring}
                range={range}
                channelRange={channelRange}
              />
            );
          }}
        />
      );
    }

    if (selectedTab === 'resistanceChart') {
      return (
        <ChartWrapper
          range={range}
          onChange={setRange}
          monitoringId={monitoringId}
          monitoringName={mainMonitoring.description}
          name="resistance"
          render={({ data, loading, range }) => {
            return (
              <ResistanceChart
                data={data}
                loading={loading}
                monitoring={monitoring}
                range={range}
              />
            );
          }}
        />
      );
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={wrapper}>
      <div className={header}>
        <dl>
          <dt>{intl.formatMessage({ id: 'reference_number' })}</dt>
          <dd>{monitoring.device_data.reference_number}</dd>
        </dl>
        <dl>
          <dt>{intl.formatMessage({ id: 'description' })}</dt>
          <dd>{monitoring.description}</dd>
        </dl>
        <dl>
          <dd>
            {mainMonitoring.device_data?.batt !== undefined ? (
              <BatteryStatus battery={mainMonitoring.device_data?.batt} />
            ) : (
              ''
            )}
          </dd>
        </dl>
        <dl>
          <dt>{intl.formatMessage({ id: 'daysElapsed' })}</dt>
          <dd>
            {intl.formatMessage(
              { id: 'days' },
              {
                total:
                  dayjs(monitoring.data?.lastTs).diff(
                    getMinDate(mainMonitoring),
                    'days',
                  ) || 0,
              },
            )}
          </dd>
        </dl>
        <dl>
          <dt>{intl.formatMessage({ id: 'lastTs' })}</dt>
          <dd>{dayjs(monitoring.data?.lastTs).format('DD/MM/YYYY HH:mm')}</dd>
        </dl>
      </div>
      <div className={tabs}>
        <AccionaSelect
          onChange={setSelectedTab}
          value={selectedTab}
          options={[
            {
              label: intl.formatMessage({
                id: 'strength.thermalcontrol.monitorings.view.tabs.selector.Data',
              }),
              value: 'data',
            },
            {
              label: intl.formatMessage({
                id: 'strength.thermalcontrol.monitorings.view.tabs.selector.TemperatureChart',
              }),
              value: 'temperatureChart',
            },
            {
              label: intl.formatMessage({
                id: 'strength.thermalcontrol.monitorings.view.tabs.selector.GradientChart',
              }),
              value: 'gradientChart',
            },
            ...(monitoring.mixture
              ? [
                  {
                    label: intl.formatMessage({
                      id: `strength.thermalcontrol.monitorings.view.tabs.selector.${
                        monitoring.mixture_data?.maturity_method ===
                        MaturityMethods.NURSE_SAUL
                          ? 'MaturityChart'
                          : 'EquivalentAgeChart'
                      }`,
                    }),
                    value:
                      monitoring.mixture_data?.maturity_method ===
                      MaturityMethods.NURSE_SAUL
                        ? 'maturityChart'
                        : 'equivalentAgeChart',
                  },
                ]
              : []),
            ...(monitoring.mixture
              ? [
                  {
                    label: intl.formatMessage({
                      id: 'strength.thermalcontrol.monitorings.view.tabs.selector.ResistanceChart',
                    }),
                    value: 'resistanceChart',
                  },
                ]
              : []),
          ]}
        />
      </div>
      {getComponent()}
      <div className={`${footer}`}>
        <AccionaButton
          type="primary"
          msg={intl.formatMessage({ id: 'close' })}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

View.propTypes = {
  monitoringId: PropTypes.number,
  onCancel: PropTypes.func,
};

View.defaultProps = {
  monitoringId: undefined,
  onCancel: () => {},
};
export default View;
