import { getQueryData } from '../../utils/parseFilters';
import axios from './api';
import { getUrl } from './utils';

const endPoint = getUrl('api') + '/api/v2/users';

export const getUserById = async (id) => {
  return axios.get(endPoint.concat(`/${id}`)).then((res) => res);
};

export const getUser = (id) =>
  axios
    .get(endPoint.concat(`/${id}`))
    .then((res) => res)
    .catch((error) => error.response);

export const getUsers = async ({ page, size, filters }) => {
  if (filters.project || filters.project === undefined) {
    delete filters.project;
  }
  return axios
    .get(endPoint.concat(getQueryData(page, size, filters)))
    .then((res) => res);
};

export const getRoles = async () => {
  return axios.get(endPoint.concat('/roles')).then((res) => res);
};

export const createUser = async (data) => {
  return axios
    .post(endPoint, data)
    .then((res) => res)
    .catch((error) => error.response);
};

export const editUser = async (data) => {
  return axios
    .put(endPoint.concat('/', data.id), data)
    .then((res) => res)
    .catch((error) => error.response);
};

export const updateUserPassword = async ({ id, ...data }) => {
  return axios
    .put(endPoint.concat('/', id, '/password'), data)
    .then((res) => res)
    .catch((error) => error.response);
};

export const deleteUser = async ({ id }) => {
  return axios
    .delete(endPoint.concat(`/${id}`))
    .then((res) => res)
    .catch((error) => error.response);
};
