import React from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import Layout from '../Layout';
import Project from '../pages/Project';
import Login from '../pages/Login';
import PreRegisterWizard from '../pages/Project/modules/ocr/PreRegisterWizard';
import PreDNList from '../pages/Project/modules/ocr/PreRegistersList';
import Projects from '../pages/Projects';
import OCRHome from '../pages/Project/modules/ocr/Home';
import ProtectedComponent from '../pages/Utils/ProtectedComponent';
import { useIntl } from 'react-intl';
import StrengthHome from '../pages/Project/modules/strength/Home';
import ThermalControlHome from '../pages/Project/modules/strength/thermalControl/Home';
import ThermalControlDevicesList from '../pages/Project/modules/strength/thermalControl/Devices/List';
import ThermalControlMonitoringsList from '../pages/Project/modules/strength/thermalControl/Monitorings/List';
import ThermalControlMixturesList from '../pages/Project/modules/strength/thermalControl/Mixtures/List';
import HoodedControlHome from '../pages/Project/modules/strength/hoodedControl/Home';
import HoodedControlDevicesList from '../pages/Project/modules/strength/hoodedControl/Devices/List';
import HoodedControlMonitoringsList from '../pages/Project/modules/strength/hoodedControl/Monitorings/List';
import UsersList from '../pages/Users/List';
import Create from '../pages/Users/Create';
import NotificationsProvider from '../context/NotificationsContext';

function Router() {
  const intl = useIntl();
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/app',
      element: (
        <NotificationsProvider>
          <Layout />
        </NotificationsProvider>
      ),
      children: [
        {
          path: 'profile',
          element: <Create />,
          handle: {
            crumb: intl.formatMessage({
              id: 'profile',
            }),
          },
        },
        {
          path: 'users',
          handle: {
            crumb: intl.formatMessage({
              id: 'users',
            }),
            fullnavigation: '/app/users',
          },
          element: <ProtectedComponent roles={['ADMIN']} />,
          children: [
            {
              path: '',
              element: <UsersList />,
            },
            {
              path: 'create',
              element: <Create />,
              handle: {
                crumb: intl.formatMessage({ id: 'users.create' }),
              },
            },
            {
              path: 'edit/:userId',
              element: <Create />,
              handle: {
                crumb: intl.formatMessage({ id: 'users.edit' }),
              },
            },
          ],
        },
        {
          path: 'projects',
          element: <Projects />,
          handle: {
            crumb: intl.formatMessage({ id: 'projects' }),
          },
        },
        {
          path: 'project/:id',
          element: <ProtectedComponent />,
          handle: {
            crumb: intl.formatMessage({ id: 'project' }),
            isProjectHome: true,
          },
          children: [
            {
              path: '',
              element: <Project />,
            },
            {
              path: 'ocr',
              handle: {
                crumb: intl.formatMessage({ id: 'ocrModule' }),
              },
              element: (
                <ProtectedComponent
                  roles={['USER TOTAL', 'ADMIN', 'USER CALIDAD']}
                />
              ),
              children: [
                {
                  path: '',
                  element: <OCRHome />,
                },
                {
                  path: 'preregisterList',
                  element: <PreDNList />,
                  handle: {
                    crumb: intl.formatMessage({ id: 'preregisters' }),
                  },
                },
                {
                  path: ':model/preregister',
                  element: <PreRegisterWizard />,
                  handle: {
                    crumb: intl.formatMessage({ id: 'preregistration' }),
                  },
                },
                {
                  path: '*',
                  element: <Navigate to="../" replace />,
                },
              ],
            },
            {
              path: 'strength',
              handle: {
                crumb: intl.formatMessage({ id: 'strengthModule' }),
              },
              element: (
                <ProtectedComponent
                  roles={['USER TOTAL', 'ADMIN', 'USER MADUREZ']}
                />
              ),
              children: [
                {
                  path: '',
                  element: <StrengthHome />,
                },
                {
                  path: 'thermalControl',
                  handle: {
                    crumb: intl.formatMessage({ id: 'thermalControl' }),
                  },
                  children: [
                    {
                      path: '',
                      element: <ThermalControlHome />,
                    },
                    {
                      path: 'mixtures',
                      element: <ThermalControlMixturesList />,
                      handle: {
                        crumb: intl.formatMessage({ id: 'mixtures' }),
                      },
                    },
                    {
                      path: 'devices',
                      element: <ThermalControlDevicesList />,
                      handle: {
                        crumb: intl.formatMessage({ id: 'devices' }),
                      },
                    },
                    {
                      path: 'monitorings',
                      element: <ThermalControlMonitoringsList />,
                      handle: {
                        crumb: intl.formatMessage({ id: 'monitorings' }),
                      },
                    },
                  ],
                },
                {
                  path: 'hoodedControl',
                  handle: {
                    crumb: intl.formatMessage({ id: 'hoodedControl' }),
                  },
                  children: [
                    {
                      path: '',
                      element: <HoodedControlHome />,
                    },
                    {
                      path: 'devices',
                      element: <HoodedControlDevicesList />,
                      handle: {
                        crumb: intl.formatMessage({ id: 'devices' }),
                      },
                    },
                    {
                      path: 'monitorings',
                      element: <HoodedControlMonitoringsList />,
                      handle: {
                        crumb: intl.formatMessage({ id: 'monitorings' }),
                      },
                    },
                  ],
                },
              ],
            },

            {
              path: '*',
              element: <Navigate to="../" replace />,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to="/app/projects" replace />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/login" replace />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default Router;
