class User {
  constructor({ id, username, name, email, role, projects }) {
    this.id = id;
    this.email = email;
    this.username = username;
    this.name = name;
    this.role = role;
    this.projects = projects;
  }
}

export default User;
