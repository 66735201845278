import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Line } from '@ant-design/plots';
import { useIntl } from 'react-intl';
import chartConfig from './index.config';
import dayjs from 'dayjs';

const AverageTemperatureChart = ({ data, loading, range }) => {
  const intl = useIntl();
  const plot = useRef();
  const sliderRange = useRef([0, 1]);

  useEffect(() => {
    const _plot = plot.current;
    const handler = ({ view: { controllers } }) => {
      const { end, start } = controllers[4];
      sliderRange.current = [start, end];
    };
    _plot?.getChart().on('slider:mouseup', handler);

    return () => {
      _plot?.getChart().off('slider:mouseup', handler);
    };
  }, []);

  useEffect(() => {
    sliderRange.current = [0, 1];
  }, [range]);

  return (
    <>
      <Line
        {...chartConfig}
        data={data || []}
        loading={loading || !data}
        autoFit={false}
        height={300}
        meta={{
          timestamp: {
            tickCount: 5,
            formatter: (v, i) => dayjs(v).format('DD/MM/YYYY'),
            type: 'time',
          },
          temperature: {
            formatter: (v) => `${v}ºC`,
          },
        }}
        xAxis={{
          title: {
            text: intl.formatMessage({
              id: 'strength.thermalcontrol.mixtures.view.charts.date',
            }),
          },
          label: {
            offset: 10,
          },
          //max: range[1].toISOString(),
        }}
        yAxis={{
          title: {
            text: intl.formatMessage({
              id: 'strength.thermalcontrol.mixtures.view.charts.temperature',
            }),
          },
        }}
        tooltip={{
          formatter: ({ temperature, timestamp }) => {
            return {
              name: intl.formatMessage({
                id: `strength.thermalcontrol.mixtures.view.charts.temperature`,
              }),
              title: dayjs(timestamp).format('DD/MM/YYYY HH:mm'),
              value: `${temperature} ºC`,
            };
          },
        }}
        slider={{
          formatter: (v) => dayjs(v).format('DD/MM/YYYY'),
          foregroundStyle: { fill: '#ff000090' },
          height: 30,
          textStyle: { fontWeight: 600, fill: 'black' },
          start: sliderRange.current[0],
          end: sliderRange.current[1],
        }}
        ref={plot}
      />
    </>
  );
};

AverageTemperatureChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.string,
      maturity: PropTypes.number,
      temperature: PropTypes.number,
      timestamp: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  range: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
};

AverageTemperatureChart.defaultProps = {
  loading: false,
  data: undefined,
  range: [],
};

export default React.memo(AverageTemperatureChart);
