/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { PropTypes } from 'prop-types';

const AccionaBigButton = ({ msg, icon, onClick, disable }) => (
  <div className={`home-button ${icon} ${disable ? 'disabled' : ''}`}>
    <a
      className="home-button"
      id="AccionaBigUploadButton"
      onClick={() => {
        if (!disable) {
          onClick();
        }
      }}
    >
      <div className="image-btn"> </div>
      <span> {msg}</span>
    </a>
  </div>
);

AccionaBigButton.propTypes = {
  msg: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  disable: PropTypes.bool,
};

AccionaBigButton.defaultProps = {
  msg: 'UPLOAD',
  icon: 'data',
  disable: false,
};

export default AccionaBigButton;
