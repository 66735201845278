import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { useIntl } from 'react-intl';
import AccionaSpinner from '../AccionaSpinner';

const { wrapper, header, card, cards, footer, spinnerWrapper, noResults } =
  styles;

const Card = ({ columns, data, actions, headingWidth }) => {
  const intl = useIntl();
  return (
    <div className={card}>
      {Object.keys(columns).map((k) => {
        return (
          <p key={k}>
            <span style={{ width: headingWidth }}>
              {intl.formatMessage({ id: k })}
            </span>
            <span>{data[k]}</span>
          </p>
        );
      })}
      {actions && <div className={footer}>{actions(data)}</div>}
    </div>
  );
};

Card.propTypes = {
  data: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  actions: PropTypes.func,
  headingWidth: PropTypes.string.isRequired,
};

Card.defaultProps = {
  actions: undefined,
};

const AccionaList = ({
  getItems,
  columns,
  actions,
  onChange,
  pid,
  dataParseFunction,
  title,
  entity,
  headingWidth,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [page, setPage] = useState(1);
  const observerRef = useRef();
  const pageSize = 5;
  const { items, total, fetching } = useSelector((state) => ({
    items: state[entity].list,
    total: state[entity].total,
    fetching: state[entity].fetching,
  }));
  const [fetchedFirstTime, setFetchedFirstTime] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'EMPTY_' + entity.toUpperCase() + '_LIST',
    });
  }, [dispatch, entity]);

  useEffect(() => {
    dispatch(
      getItems({
        size: pageSize,
        page,
        filters: { project: pid },
      }),
    );

    setFetchedFirstTime(true);
  }, [page, pageSize, getItems, pid, dispatch]);

  const lastCardElementRef = useCallback(
    (node) => {
      if (fetching || (total !== null && items.length >= total)) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [fetching, items.length, total],
  );

  useEffect(() => {
    onChange({ page });
  }, [page, onChange]);

  const results = dataParseFunction ? dataParseFunction(items) : items;

  return (
    <div className={wrapper}>
      {title && <div className={header}>{title}</div>}
      <div className={cards}>
        {results.map((r, index) => {
          if (index === results.length - 1) {
            return (
              <div ref={lastCardElementRef} key={r.id}>
                <Card
                  columns={columns}
                  data={r}
                  actions={actions}
                  headingWidth={headingWidth}
                />
              </div>
            );
          } else {
            return (
              <Card
                columns={columns}
                data={r}
                actions={actions}
                key={r.id}
                headingWidth={headingWidth}
              />
            );
          }
        })}
      </div>
      {fetching && (
        <div className={spinnerWrapper}>
          <AccionaSpinner />
        </div>
      )}
      {!fetching && fetchedFirstTime && items.length === 0 && (
        <div className={noResults}>
          {intl.formatMessage({ id: 'noResults' })}
        </div>
      )}
    </div>
  );
};

AccionaList.propTypes = {
  getItems: PropTypes.func.isRequired,
  entity: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  dataParseFunction: PropTypes.func,
  columns: PropTypes.object.isRequired,
  actions: PropTypes.func,
  pid: PropTypes.string,
  title: PropTypes.string,
  headingWidth: PropTypes.string,
};

AccionaList.defaultProps = {
  onChange: () => {},
  actions: undefined,
  dataParseFunction: undefined,
  headingWidth: '120px',
  title: undefined,
  pid: undefined,
};

export default AccionaList;
