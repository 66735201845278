const GET_USER_REQUEST = 'GET_USER_REQUEST';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_ERROR = 'GET_USER_ERROR';

const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
const EDIT_USER_ERROR = 'EDIT_USER_ERROR';

const EDIT_USER_ME_REQUEST = 'EDIT_USER_ME_REQUEST';
const EDIT_USER_ME_SUCCESS = 'EDIT_USER_ME_SUCCESS';
const EDIT_USER_ME_ERROR = 'EDIT_USER_ME_ERROR';

const GET_USER_ME_REQUEST = 'GET_USER_ME_REQUEST';
const GET_USER_ME_SUCCESS = 'GET_USER_ME_SUCCESS';
const GET_USER_ME_ERROR = 'GET_USER_ME_ERROR';

const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USERS_ERROR = 'GET_USERS_ERROR';

const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
const UPDATE_USER_PASSWORD_ERROR = 'UPDATE_USER_PASSWORD_ERROR';

const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

const CLEAR_USERS_STATE = 'CLEAR_USERS_STATE';
const CLEAR_ERRORS = 'CLEAR_ERRORS';
const CLEAR_ERRORS_PROFILE = 'CLEAR_ERRORS_PROFILE';
const INIT_REDUCER = 'INIT_REDUCER';
const CLEAR_CREATE_STATE = 'CLEAR_CREATE_STATE';

const CREATE_ERRORS = 'CREATE_ERRORS';

export {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  EDIT_USER_ME_REQUEST,
  EDIT_USER_ME_SUCCESS,
  EDIT_USER_ME_ERROR,
  GET_USER_ME_REQUEST,
  GET_USER_ME_SUCCESS,
  GET_USER_ME_ERROR,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
  CLEAR_USERS_STATE,
  CLEAR_ERRORS,
  CLEAR_ERRORS_PROFILE,
  CREATE_ERRORS,
  CLEAR_CREATE_STATE,
  INIT_REDUCER,
};
