import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import MaturityMethods from '../../../../../../../../../constants/maturityMethods';
import Mixture from '../../../../../../../../../models/thermalControl/Mixture';

const { parameters, parameter } = styles;

const MixtureData = ({ mixture }) => {
  const intl = useIntl();

  return (
    <>
      <div className={parameters}>
        {mixture.parameters.map((param) => (
          <div className={parameter} key={param.datetime}>
            <div>
              <p>{intl.formatMessage({ id: 'ts' })}:</p>
              <p>{dayjs(param.datetime).format('DD/MM/YYYY HH:mm')}</p>
            </div>
            <div>
              <p style={{ whiteSpace: 'pre' }}>
                {intl.formatMessage({
                  id: `strength.thermalcontrol.mixtures.create.params.${
                    mixture.maturity_method === MaturityMethods.ARRHENIUS
                      ? 'equivalentAge'
                      : 'maturity'
                  }`,
                })}{' '}
                {mixture.maturity_method === MaturityMethods.ARRHENIUS ? (
                  <span
                    style={{ textTransform: 'none' }}
                  >{`${intl.formatMessage({
                    id: 'strength.thermalcontrol.mixtures.create.params.days',
                  })}`}</span>
                ) : (
                  'ºC-h'
                )}
                :
              </p>
              <p>{parseFloat(param.maturity.toFixed(4))}</p>
            </div>
            <div>
              <p style={{ whiteSpace: 'pre' }}>
                {intl.formatMessage({ id: 'resistance' })}
                <span> MPa</span>:
              </p>
              <p>{parseFloat(param.resistance.toFixed(4))}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

MixtureData.propTypes = {
  mixture: PropTypes.instanceOf(Mixture).isRequired,
};

export default MixtureData;
