import { Divider, Form } from 'antd';
import AntdFormItem from '../../../components/AntdFormItem';
import AntdInput from '../../../components/AntdInput';
import AntdSelect from '../../../components/AntdSelect';
import { useIntl } from 'react-intl';
import AccionaButton from '../../../components/AccionaButton';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import AntdInputPassword from '../../../components/AntdInputPassword';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import {
  clearCreateUsersState,
  createUser,
  getRoles,
  getUser,
  editUser,
  editUserMe,
  changeUserPassword,
  clearUsersState,
} from '../../../services/redux/users/actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NotificationsContext } from '../../../context/NotificationsContext';
import AccionaResult from '../../../components/AccionaResult';
import { useForm } from 'antd/es/form/Form';
import { ConnectionContext } from '../../../context/ConnectionContext/provider';

const Create = () => {
  const intl = useIntl();
  const [form] = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userId } = useParams();
  const { list: projects } = useSelector(({ projects }) => projects);
  const { me } = useSelector(({ auth }) => auth);
  const {
    roles,
    isCreated,
    errorMessages,
    list: users,
  } = useSelector(({ users }) => users);
  const { setLoading } = useContext(ConnectionContext);
  const { pushNotification } = useContext(NotificationsContext);
  const [role, setRole] = useState();
  const isAdmin = me?.role === 'ADMIN';
  const isProfilePage = pathname.includes('profile');

  const handleOnFinish = async (values) => {
    setLoading(true);
    if (userId || isProfilePage) {
      if (userId) {
        dispatch(
          editUser({
            id: userId || me.id,
            ...values,
            projects: values.projects || [],
          }),
        );
      } else {
        if (isAdmin) {
          dispatch(
            editUserMe({
              id: me.id,
              ...values,
              projects: values.projects || [],
            }),
          );
        } else {
          if (values.password && values.repassword) {
            dispatch(
              changeUserPassword({
                id: userId || me.id,
                new_password: values.password,
                old_password: values.old_password,
              }),
            );
          }
        }
      }
      setLoading(false);
    } else {
      dispatch(
        createUser({
          ...values,
          projects: values.projects || [],
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  useEffect(() => {
    if (isCreated) {
      setLoading(false);
    }
  }, [isCreated, setLoading]);

  useEffect(() => {
    if (errorMessages) {
      setLoading(false);
      if (errorMessages.status === 404 && userId) {
        navigate('/app/users');
      } else if (
        errorMessages.status === 400 &&
        errorMessages.message?.includes('exists')
      ) {
        pushNotification({
          message: intl.formatMessage(
            { id: 'messages.user.already.exists' },
            { user: form.getFieldValue('username') },
          ),
        });
      } else if (
        errorMessages.status === 400 &&
        (errorMessages.message?.includes('passwords do not match') ||
          (Array.isArray(errorMessages.message) &&
            errorMessages.message?.some((e) =>
              e.includes('old_password must be'),
            )))
      ) {
        pushNotification({
          message: intl.formatMessage({
            id: 'messages.user.passwords.do.not.match',
          }),
        });
      } else {
        pushNotification({
          message: intl.formatMessage({ id: 'messages.default' }),
        });
      }
    }
  }, [
    errorMessages,
    form,
    intl,
    navigate,
    pushNotification,
    setLoading,
    userId,
  ]);

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (userId && users?.length && roles?.length) {
      const user = users.find((user) => user.id === parseInt(userId, 10));
      const role = roles.find((r) => r.role_name === user?.role);
      form.setFieldsValue({
        ...user,
        id_role: role.id_role,
        projects: user.projects?.map((project) => project.id_project),
      });
      setRole(role.role_name);
    }
  }, [form, roles, userId, users]);

  useEffect(() => {
    if (isProfilePage && roles && me) {
      const user = me;
      const role = roles.find((r) => r.role_name === user.role);
      form.setFieldsValue({
        ...user,
        id_role: role.id_role,
      });
      setRole(role.role_name);
    }
  }, [form, isProfilePage, me, roles]);

  useEffect(() => {
    return () => {
      dispatch(clearUsersState());
    };
  }, [dispatch]);

  if (isCreated) {
    return (
      <AccionaResult
        id="succesResult"
        icon={
          <i
            className="fas fa-check-circle fa-8x"
            style={{ color: '#82c91e' }}
          />
        }
        title={intl.formatMessage({
          id: `users.${
            userId || isProfilePage ? 'edit' : 'create'
          }.result.title`,
        })}
        className="success"
        extra={[
          <div key="1" style={{ marginTop: '24px' }}>
            <div key="extraSuccess1" className="form-row">
              <AccionaButton
                id="successBtn"
                onClick={() => {
                  dispatch(clearCreateUsersState());
                  dispatch(clearUsersState());
                  navigate(-1);
                }}
                msg={intl.formatMessage({ id: 'button.create.end' })}
              />
            </div>
          </div>,
        ]}
      />
    );
  }

  return (
    <div
      className="col-sm-12"
      style={{
        backgroundColor: '#ffffff',
        padding: '20px',
        paddingBottom: '100px',
      }}
    >
      <Form layout="vertical" form={form} onFinish={handleOnFinish}>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <AntdFormItem
              name="name"
              label={intl.formatMessage({
                id: 'users.create.name.label',
              })}
              style={{ marginBottom: '20px' }}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'required' }),
                },
                { min: 5 },
                { max: 50 },
              ]}
            >
              <AntdInput
                placeholder={intl.formatMessage({
                  id: 'users.create.name.placeholder',
                })}
                size="xLarge"
                disabled={!isAdmin}
              />
            </AntdFormItem>
            <AntdFormItem
              name="username"
              label={intl.formatMessage({
                id: 'users.create.username.label',
              })}
              style={{ marginBottom: '20px' }}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'required' }),
                },
                { min: 5 },
                { max: 15 },
                {
                  pattern: /^[a-zA-Z0-9]+$/,
                  message: intl.formatMessage({
                    id: 'validations.notSpecialChars',
                  }),
                },
              ]}
            >
              <AntdInput
                placeholder={intl.formatMessage({
                  id: 'users.create.username.placeholder',
                })}
                size="xLarge"
                disabled={!isAdmin}
              />
            </AntdFormItem>
            <AntdFormItem
              name="email"
              label={intl.formatMessage({
                id: 'users.create.email.label',
              })}
              style={{ marginBottom: '20px' }}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'required' }),
                },
                {
                  type: 'email',
                  message: intl.formatMessage({ id: 'validations.email' }),
                },
              ]}
            >
              <AntdInput
                placeholder={intl.formatMessage({
                  id: 'users.create.email.placeholder',
                })}
                size="xLarge"
                type="email"
                disabled={!isAdmin}
              />
            </AntdFormItem>

            <AntdFormItem
              name="id_role"
              label={intl.formatMessage({
                id: 'users.create.roles.label',
              })}
              style={{ marginBottom: '20px' }}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'required' }),
                },
              ]}
            >
              <AntdSelect
                placeholder={intl.formatMessage({
                  id: 'users.create.roles.placeholder',
                })}
                size="xLarge"
                options={roles?.map((role) => ({
                  value: role.id_role,
                  label: intl.formatMessage({
                    id: `roles.${role.role_name
                      .toLowerCase()
                      .replace(/ /g, '')}`,
                  }),
                }))}
                onChange={(value) => setRole(value)}
                disabled={
                  !isAdmin || userId === '2' || (!userId && me?.id === 2)
                }
              />
            </AntdFormItem>
            {role && role !== 'ADMIN' && !isProfilePage ? (
              <AntdFormItem
                name="projects"
                label={intl.formatMessage({
                  id: 'users.create.projects.label',
                })}
                style={{ marginBottom: '20px' }}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'required' }),
                  },
                ]}
                disabled
              >
                <AntdSelect
                  placeholder={intl.formatMessage({
                    id: 'users.create.projects.placeholder',
                  })}
                  size="xLarge"
                  options={projects?.map((project) => ({
                    value: project.id_project,
                    label: project.name,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  mode="multiple"
                />
              </AntdFormItem>
            ) : null}
          </div>
          {(!userId || (userId && me?.id === parseInt(userId, 10))) && (
            <div className="col-sm-12 col-md-6">
              {((userId && me?.id === parseInt(userId, 10)) ||
                isProfilePage) && (
                <AntdFormItem
                  style={{ marginBottom: '20px' }}
                  name="old_password"
                  label={intl.formatMessage({
                    id: 'users.create.oldPassword.label',
                  })}
                  rules={[
                    {
                      required: !(userId || isProfilePage),
                      message: intl.formatMessage({ id: 'required' }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (getFieldValue('password') && !value) {
                          return Promise.reject(
                            new Error(intl.formatMessage({ id: 'required' })),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <AntdInputPassword
                    placeholder={intl.formatMessage({
                      id: 'users.create.oldPassword.placeholder',
                    })}
                    size="xLarge"
                    type="password"
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                    onChange={() => {
                      if (userId) {
                        form.validateFields(['repassword']);
                      }
                    }}
                  />
                </AntdFormItem>
              )}
              <AntdFormItem
                style={{ marginBottom: '20px' }}
                name="password"
                label={intl.formatMessage({
                  id: `users.create.${
                    userId || isProfilePage ? 'newPassword' : 'password'
                  }.label`,
                })}
                rules={[
                  {
                    required: !(userId || isProfilePage),
                    message: intl.formatMessage({ id: 'required' }),
                  },
                  {
                    max: 25,
                  },
                  {
                    min: 5,
                  },
                  {
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: intl.formatMessage({ id: 'validations.password' }),
                  },
                ]}
              >
                <AntdInputPassword
                  placeholder={intl.formatMessage({
                    id: `users.create.${
                      userId ? 'newPassword' : 'password'
                    }.placeholder`,
                  })}
                  size="xLarge"
                  type="password"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                  onChange={() => {
                    if (userId) {
                      form.validateFields(['repassword']);
                    }
                  }}
                />
              </AntdFormItem>
              <AntdFormItem
                style={{ marginBottom: '20px' }}
                name="repassword"
                label={intl.formatMessage({
                  id: `users.create.${
                    userId ? 'newRepassword' : 'repassword'
                  }.label`,
                })}
                rules={[
                  {
                    required: !(userId || isProfilePage),
                    message: intl.formatMessage({ id: 'required' }),
                  },
                  {
                    max: 25,
                  },
                  {
                    min: 5,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue('password') && !value) {
                        return Promise.reject(
                          new Error(intl.formatMessage({ id: 'required' })),
                        );
                      }
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          intl.formatMessage({
                            id: 'validations.passwords.not.matching',
                          }),
                        ),
                      );
                    },
                  }),
                ]}
              >
                <AntdInputPassword
                  placeholder={intl.formatMessage({
                    id: `users.create.${
                      userId || isProfilePage ? 'newRepassword' : 'repassword'
                    }.placeholder`,
                  })}
                  size="xLarge"
                  type="password"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </AntdFormItem>
            </div>
          )}
        </div>
        <Divider />
        <AccionaButton
          style={{ width: '100%', margin: ' 0 0 10px 0' }}
          onClick={() => form.submit()}
          classIcon="fas fa-check"
          msg={intl.formatMessage({
            id: userId
              ? 'users.edit.button'
              : isProfilePage
                ? 'profile.edit.button'
                : 'users.create.button',
          })}
        />
        <AccionaButton
          style={{ width: '100%', margin: 0 }}
          onClick={() => navigate(-1)}
          classIcon="fas fa-chevron-left"
          msg={intl.formatMessage({
            id: 'back',
          })}
          type="default"
        />
      </Form>
    </div>
  );
};

export default Create;
