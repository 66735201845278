import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import AccionaBigButton from '../../components/AccionaBigButton';
import { useSelector } from 'react-redux';

const Project = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { me } = useSelector(({ auth }) => auth);

  return (
    <div className="wrapper fullscreen">
      <div className="row">
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="ocr-module"
            id="ocrModule"
            onClick={() => navigate(`ocr`)}
            msg={formatMessage({ id: `ocrModule` })}
            disable={me?.role === 'USER MADUREZ'}
          />
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="strength-module"
            id="strengthModule"
            onClick={() => navigate(`strength`)}
            msg={formatMessage({ id: `strengthModule` })}
            disable={me?.role === 'USER CALIDAD'}
          />
        </div>
      </div>
    </div>
  );
};
export default Project;
