import React from 'react';
import { PropTypes } from 'prop-types';
import { Line } from '@ant-design/plots';
import { useIntl } from 'react-intl';
import chartConfig from './index.config';
import Mixture from '../../../../../../../../../models/thermalControl/Mixture';
import MaturityMethods from '../../../../../../../../../constants/maturityMethods';

const ResistanceChart = ({ mixture }) => {
  const intl = useIntl();

  return (
    <>
      <Line
        {...chartConfig}
        data={[
          ...mixture?.parameters?.map((d) => ({
            resistance: d.resistance,
            day:
              mixture.maturity_method === MaturityMethods.NURSE_SAUL
                ? d.maturity / 24
                : d.maturity,
            category: 'mixture',
          })),
          ...mixture?.graph_data?.map((d) => ({
            ...d,

            category: 'resistance',
          })),
        ].sort((a, b) => a.day - b.day)}
        height={280}
        meta={{
          day: {
            type: 'linear',
          },
        }}
        xAxis={{
          title: {
            text: intl.formatMessage({
              id: 'strength.thermalcontrol.mixtures.view.charts.days',
            }),
          },
          min: 0,
          tickInterval: 1,
          max:
            mixture.maturity_method === MaturityMethods.NURSE_SAUL
              ? mixture?.parameters?.[mixture?.parameters?.length - 1]?.maturity /
                  24 +
                3
              : mixture?.parameters?.[mixture?.parameters?.length - 1]?.maturity + 3,
        }}
        yAxis={{
          title: {
            text: intl.formatMessage({
              id: 'strength.thermalcontrol.mixtures.view.charts.resistance',
            }),
          },
          min: 0,
          max: Math.max(...mixture?.graph_data?.map((d) => d.resistance)) + 1,
        }}
        legend={false}
        tooltip={{
          formatter: ({ day, resistance, category }) => {
            return {
              name: intl.formatMessage({
                id: `strength.thermalcontrol.mixtures.view.charts.${
                  category === 'mixture' ? 'calibration' : 'resistance'
                }`,
              }),
              title: `${day.toFixed(2)} ${intl
                .formatMessage({
                  id: 'strength.thermalcontrol.mixtures.view.charts.days',
                })
                .toLowerCase()}`,
              value: `${resistance}  Mpa`,
            };
          },
        }}
        slider={{
          foregroundStyle: { fill: '#ff000090' },
          height: 30,
          textStyle: { fontWeight: 600, fill: 'black' },
          formatter: (v, datum, index) => {
            return Math.floor(datum?.day);
          },
        }}
      />
    </>
  );
};

ResistanceChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.string,
      maturity: PropTypes.number,
      temperature: PropTypes.number,
      timestamp: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  mixture: PropTypes.instanceOf(Mixture).isRequired,
};

ResistanceChart.defaultProps = {
  loading: false,
  data: [],
};

export default React.memo(ResistanceChart);
