/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getLogout } from '../../../services/redux/auth/actions';
import logoAcciona from '../../../style/imgPWA/logoAcciona.png';
import styles from './index.module.css';
import { ConnectionContext } from '../../../context/ConnectionContext/provider';
import { getProject } from '../../../utils/utils';
import { Dropdown } from 'antd';
import { LogoutOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { ReactComponent as IconUser } from '../../../style/img/icon_user_dark.svg';

const { header, userZone } = styles;

const Header = () => {
  const intl = useIntl();
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { online, setLoading } = useContext(ConnectionContext);
  const project = getProject(params.id);
  const { me } = useSelector((state) => state.auth);

  useEffect(() => {
    const listener = () => online.setOnline(false);
    window.addEventListener('offline', listener);

    return () => {
      window.removeEventListener('offline', listener);
    };
  }, [online]);

  useEffect(() => {
    const listener = () => {
      online.setOnline(true);

      const controlRequest = localStorage.getItem('controlRequest');

      if (controlRequest !== null && controlRequest === 'false') {
        localStorage.setItem('controlRequest', true);
      }
    };
    window.addEventListener('online', listener);
  }, [online, setLoading]);

  useEffect(() => {
    const listener = ({ target }) => {
      if (
        !target.classList.contains('profile-menu') &&
        !target.parentNode?.classList.contains('profile-button')
      ) {
        setShowProfileMenu(false);
      }
    };
    if (showProfileMenu) {
      document.addEventListener('click', listener);
    }
    return () => {
      if (listener) {
        document.removeEventListener('click', listener);
      }
    };
  }, [showProfileMenu]);

  const ProjectsButton = () => (
    <button
      className="projectsButton"
      onClick={() => {
        navigate('/app/projects');
      }}
    >
      <span>{formatMessage({ id: `projects` })}</span>
    </button>
  );

  const OfflineAlert = () => (
    <div
      style={{
        backgroundColor: 'red',
        padding: '3px 12px',
        margin: '12px',
        borderRadius: '15px',
        color: 'white',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {formatMessage({ id: `offlineMode` })}
    </div>
  );

  return (
    <header className={header}>
      <div>
        <a href="/">
          <img id="logo" src={logoAcciona} alt="logo"></img>
        </a>
      </div>
      <div>
        {project &&
          !pathname.includes('projects') &&
          intl.formatMessage(
            { id: 'header.project' },
            {
              center: project.name,
              code: project.work_code,
            },
          )}
        {online.state ? null : <OfflineAlert />}
      </div>
      <div>
        <ProjectsButton />
        <Dropdown
          position="bottom"
          menu={{
            items: [
              {
                key: 'name',
                label: me?.name,
              },
              {
                type: 'divider',
              },
              {
                key: 'profile',
                label: (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/app/profile');
                    }}
                  >
                    <UserOutlined />{' '}
                    {intl.formatMessage({ id: 'header.profile' })}
                  </a>
                ),
              },
              ...(me?.role === 'ADMIN'
                ? [
                    {
                      key: 'users',
                      label: (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            navigate('/app/users');
                          }}
                        >
                          <TeamOutlined />{' '}
                          {intl.formatMessage({ id: 'header.users' })}
                        </a>
                      ),
                    },
                  ]
                : []),
              {
                key: 'logout',
                label: (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(getLogout());
                    }}
                  >
                    <LogoutOutlined />{' '}
                    {intl.formatMessage({ id: 'header.logout' })}
                  </a>
                ),
              },
            ],
          }}
        >
          <div className={userZone}>
            <a onClick={(e) => e.preventDefault()}>
              <IconUser />
            </a>
          </div>
        </Dropdown>
      </div>
    </header>
  );
};

export default Header;
