import { useContext, useMemo, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import AccionaFormItem from '../../../../../../../components/AccionaFormItem';
import AccionaInput from '../../../../../../../components/AccionaInput';
import style from './index.module.scss';
import AccionaButton from '../../../../../../../components/AccionaButton';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import AccionaAlert from '../../../../../../../components/AccionaAlert';
import AccionaSelect from '../../../../../../../components/AccionaSelect';
import Monitoring from '../../../../../../../models/thermalControl/Monitoring';
import SensorsFormField from './components/SensorsFormField';
import AccionaConfirm from '../../../../../../../components/AccionaConfirm';
import AccionaListButton from '../../../../../../../components/AccionaList/AccionaListButton';
import ViewMixture from '../../Mixtures/View';
import Modal from '../../../../../../../components/AccionaModal';
import { areObjectsEqual } from '../../../../../../../utils/utils';
import { getUnusedDevices } from '../../../../../../../services/redux/strength/thermalControl/devices/actions';
import { getMixtures } from '../../../../../../../services/redux/strength/thermalControl/mixtures/actions';
import { ConnectionContext } from '../../../../../../../context/ConnectionContext/provider';
import { useOutletContext } from 'react-router-dom';

const { wrapper, footer, sensors, main, showAddButton, hiddenZone, visible } =
  style;

const CreateMonitoring = ({
  initialData,
  onFinish,
  onCancel,
  onFinishComplete,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { setLoading: setMainLoading } = useContext(ConnectionContext);
  const { project } = useOutletContext();

  const { errorCreated } = useSelector(
    ({ thermalControlMonitorings }) => thermalControlMonitorings,
  );
  const { unusedList, fetching: loading } = useSelector(
    ({ thermalControlDevices }) => thermalControlDevices,
  );
  const { list: mixtures } = useSelector(
    ({ thermalControlMixtures }) => thermalControlMixtures,
  );
  const [monitoring, setMonitoring] = useState(
    initialData || new Monitoring({ project: project.id_project }),
  );
  const [isMonitoringIdValidated, setIsMonitoringIdValidated] = useState(
    initialData || false,
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [mixtureForView, setMixtureForView] = useState();

  const hasTheMonitoringChange = useMemo(() => {
    return initialData && !areObjectsEqual(initialData, monitoring);
  }, [initialData, monitoring]);

  const onAddClick = () => {
    const parsedMonitoring = new Monitoring({
      ...monitoring,
      mixture: monitoring.mixture === 'withoutMix' ? null : monitoring.mixture,
    });
    [...Array(5)].forEach((_, index) => {
      const channel = monitoring[`ch${index + 1}`];
      if (channel && channel.enabled === undefined) {
        channel.enabled = false;
      }
    });
    onFinish(parsedMonitoring);
  };

  const onFinishClick = () => {
    setShowConfirmModal({
      message: intl.formatMessage({
        id: 'strength.thermalcontrol.monitorings.create.finishMonitoring.message',
      }),
      onConfirm: () => {
        onFinishComplete({
          ...monitoring,
          mixture:
            monitoring.mixture === 'withoutMix' ? null : monitoring.mixture,
        });
        setShowConfirmModal();
      },
      onCancel: () => {
        setShowConfirmModal();
      },
    });
  };

  const validationError = useMemo(() => {
    let e = false;
    if (!monitoring.mixture || !monitoring.description || !monitoring.device) {
      return true;
    }
    [...Array(5)].forEach((_, index) => {
      const channel = monitoring[`ch${index + 1}`];
      if (channel?.enabled && !channel?.ts) {
        e = true;
      }
    });
    return e;
  }, [monitoring]);

  useEffect(() => {
    if (errorCreated) {
      setMainLoading(false);
    }
  }, [setMainLoading, errorCreated]);

  useEffect(() => {
    dispatch(getUnusedDevices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMixtures({ size: 1000, page: 1 }));
  }, [dispatch]);

  return (
    <div className={wrapper}>
      <div className={main}>
        <div>
          <AccionaFormItem>
            <AccionaInput
              state={{ value: monitoring.description, status: [] }}
              label="description"
              onChange={(description) => {
                setMonitoring((prev) => ({ ...prev, description }));
              }}
            />
          </AccionaFormItem>
          <AccionaFormItem>
            <AccionaSelect
              state={{
                value: monitoring.device,
                status: [],
                rules: { required: true },
              }}
              loading={loading}
              disabled={!unusedList.length && !loading}
              defaultValue={
                unusedList.length || monitoring.device
                  ? {
                      label: monitoring.device_data?.reference_number,
                      id: monitoring.device,
                    }
                  : {
                      label: `-- ${intl.formatMessage({
                        id: 'noUnusedDevices',
                      })} --`,
                    }
              }
              value={monitoring.device}
              label="device"
              onChange={(device) => {
                setMonitoring((prev) => ({ ...prev, device }));
                setIsMonitoringIdValidated(true);
              }}
              options={
                unusedList?.map((device) => ({
                  label: device.reference_number,
                  value: device.id,
                })) || []
              }
            />
          </AccionaFormItem>
        </div>
        <div
          className={`${hiddenZone} ${isMonitoringIdValidated ? visible : ''}`}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccionaFormItem>
              <AccionaSelect
                state={{
                  value: monitoring.mixture,
                  status: [],
                  rules: { required: true },
                }}
                value={monitoring.mixture}
                label="mixture"
                onChange={(mixture) => {
                  setMonitoring((prev) => ({ ...prev, mixture }));
                }}
                options={[
                  { label: 'Sin Mezcla', value: 'withoutMix' },
                  ...mixtures.map((mixture) => ({
                    label: `${mixture.name} (${mixture.maturity_method})`,
                    value: mixture.id,
                  })),
                ]}
              />
            </AccionaFormItem>
            <AccionaListButton
              onClick={() => {
                setMixtureForView(monitoring.mixture);
              }}
              disabled={
                !monitoring.mixture || monitoring.mixture === 'withoutMix'
              }
              type="view"
              toolTip={intl.formatMessage({
                id: 'viewAction.mixtures',
              })}
              style={{
                height: '45px',
                marginLeft: '10px',
                marginBottom: '3px',
              }}
            />
          </div>
          <div className={sensors}>
            <p>
              {intl.formatMessage({
                id: 'strength.thermalcontrol.monitorings.create.sensors',
              })}
            </p>
            <SensorsFormField value={monitoring} onChange={setMonitoring} />
          </div>
        </div>
        {errorCreated && (
          <AccionaAlert
            message={intl.formatMessage({
              id: 'strength.thermalcontrol.monitorings.create.error',
            })}
          />
        )}
      </div>
      <div
        className={`${footer} ${isMonitoringIdValidated ? showAddButton : ''}`}
      >
        {isMonitoringIdValidated && (
          <AccionaButton
            msg={intl.formatMessage({
              id: initialData ? 'button.update.end' : 'button.add',
            })}
            disabled={Boolean(validationError)}
            onClick={onAddClick}
          />
        )}
        {isMonitoringIdValidated && (
          <AccionaButton
            msg={intl.formatMessage({
              id: 'button.create.end',
            })}
            onClick={onFinishClick}
            disabled={
              Boolean(validationError) ||
              !monitoring.id ||
              hasTheMonitoringChange
            }
          />
        )}
        <AccionaButton
          type="default"
          msg={intl.formatMessage({ id: 'cancel' })}
          onClick={onCancel}
        />
      </div>
      {showConfirmModal && (
        <AccionaConfirm
          message={showConfirmModal.message}
          onCancel={showConfirmModal.onCancel}
          onConfirm={showConfirmModal.onConfirm}
        />
      )}

      {mixtureForView && (
        <Modal
          title={intl
            .formatMessage({
              id: 'strength.thermalcontrol.mixtures.list.modal.view.title',
            })
            .toUpperCase()}
          open={mixtureForView}
          onCancel={() => {
            setMixtureForView(undefined);
          }}
          closable
          width="90vw"
        >
          <ViewMixture
            mixtureId={mixtureForView}
            onCancel={() => {
              setMixtureForView(undefined);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

CreateMonitoring.propTypes = {
  initialData: PropTypes.instanceOf(Monitoring),
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
  onFinishComplete: PropTypes.func,
};

CreateMonitoring.defaultProps = {
  initialData: undefined,
  onFinish: () => {},
  onCancel: () => {},
  onFinishComplete: () => {},
};

export default CreateMonitoring;
