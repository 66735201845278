import { PropTypes } from 'prop-types';

const AccionaResult = ({ id, icon, title, subTitle, extra }) => {
  const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '25px',
  };

  return (
    <div style={centerStyle} id={id}>
      <div>{icon}</div>
      <div style={{ textAlign: 'center', marginTop: '25px' }}>
        <div style={{ fontSize: '24px' }}>{title}</div>
        <span style={{ color: '#8c8c8c' }}>{subTitle}</span>
      </div>
      {extra}
    </div>
  );
};

AccionaResult.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  extra: PropTypes.arrayOf(PropTypes.element).isRequired,
};

AccionaResult.defaultProps = {
  subTitle: undefined,
};

export default AccionaResult;
