import { ChartsColors } from '../../../../../../../../../constants/charts';

const chartConfig = {
  xField: 'day',
  yField: 'resistance',
  seriesField: 'category',
  theme: {
    styleSheet: {
      fontFamily: 'Acciona Font',
    },
  },
  color: ({ category }) => {
    return ChartsColors[
      category === 'resistance' ? 0 : ChartsColors.length - 1
    ];
  },
  lineStyle: ({ category }) => {
    return category === 'mixture' ? { lineWidth: 0 } : {};
  },

  point: {
    style: ({ category }) => {
      return category === 'mixture' ? { r: 5 } : { r: 0 };
    },
  },
};

export default chartConfig;
