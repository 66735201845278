import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_LOGIN_ERROR, GET_LOGIN_REQUEST, GET_LOGIN_SUCCESS } from './types';
import getLogIn from '../../api/auth';
import { encryptionKey } from '../../../constants/general';
import CryptoJS from 'crypto-js';
import { jwtDecode } from 'jwt-decode';
import { callToAPI } from '../../../utils/callToAPI';

function* getLogin({ payload }) {
  yield callToAPI({
    apiCall: getLogIn,
    successResponseCode: 201,
    errorType: GET_LOGIN_ERROR,
    *callback(response) {
      yield localStorage.setItem('token', response.data.jwt_token);
      yield localStorage.setItem('username', payload.username);
      if (payload.remember) {
        yield localStorage.setItem(
          'password',
          CryptoJS.AES.encrypt(payload.password, encryptionKey).toString(),
        );
      } else {
        yield localStorage.removeItem('password');
      }

      yield put({
        type: GET_LOGIN_SUCCESS,
        payload: {
          token: response.data.token,
          user: jwtDecode(response.data.jwt_token),
        },
      });

      yield put({
        type: 'CLEAR_ERRORS',
      });
    },
    payload,
  });
}

/* watchers */
function* watcherGetLogin() {
  yield takeEvery(GET_LOGIN_REQUEST, getLogin);
}

export default function* rootSaga() {
  yield all([fork(watcherGetLogin)]);
}
