import {
  GET_USER_REQUEST,
  GET_USERS_REQUEST,
  CLEAR_USERS_STATE,
  INIT_REDUCER,
  CLEAR_ERRORS,
  CLEAR_ERRORS_PROFILE,
  CREATE_ERRORS,
  CREATE_USER_REQUEST,
  CLEAR_CREATE_STATE,
  DELETE_USER_REQUEST,
  GET_ROLES_REQUEST,
  EDIT_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  EDIT_USER_ME_REQUEST,
  GET_USER_ME_REQUEST,
} from './types';

const getUser = (payload) => ({
  type: GET_USER_REQUEST,
  payload,
});

const getUsers = (payload) => {
  return {
    type: GET_USERS_REQUEST,
    payload,
  };
};

const getRoles = () => {
  return {
    type: GET_ROLES_REQUEST,
  };
};

const createUser = (payload) => {
  return {
    type: CREATE_USER_REQUEST,
    payload,
  };
};

const deleteUser = (payload) => {
  return {
    type: DELETE_USER_REQUEST,
    payload,
  };
};

const editUser = (payload) => {
  return {
    type: EDIT_USER_REQUEST,
    payload,
  };
};

const editUserMe = (payload) => {
  return {
    type: EDIT_USER_ME_REQUEST,
    payload,
  };
};

const getUserMe = (payload) => {
  return {
    type: GET_USER_ME_REQUEST,
    payload,
  };
};

const changeUserPassword = (payload) => {
  return {
    type: UPDATE_USER_PASSWORD_REQUEST,
    payload,
  };
};

const clearUsersState = () => ({
  type: CLEAR_USERS_STATE,
});

const clearCreateUsersState = () => ({
  type: CLEAR_CREATE_STATE,
});

const initReducer = () => ({
  type: INIT_REDUCER,
});

const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

const clearErrorsProfile = () => ({
  type: CLEAR_ERRORS_PROFILE,
});

const createErrors = (payload) => ({
  type: CREATE_ERRORS,
  payload: payload,
});

export {
  getUser,
  getUserMe,
  getUsers,
  getRoles,
  createUser,
  editUser,
  editUserMe,
  deleteUser,
  changeUserPassword,
  clearUsersState,
  initReducer,
  clearErrors,
  clearErrorsProfile,
  createErrors,
  clearCreateUsersState,
};
