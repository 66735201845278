import React, { useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import AccionaMessage from '../../components/AccionaMessage';
import { useDispatch } from 'react-redux';

export const NotificationsContext = React.createContext({});

const NotificationsProvider = ({ children }) => {
  const [message, setMessage] = useState();
  const dispatch = useDispatch();
  const pushNotification = useCallback((message) => {
    setMessage(message);
  }, []);
  return (
    <NotificationsContext.Provider value={{ pushNotification }}>
      {children}
      {message && (
        <AccionaMessage
          message={message.message}
          type={message.type}
          onClose={() => {
            setMessage();
            dispatch({ type: 'CLEAR_ERRORS' });
          }}
        />
      )}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NotificationsProvider;
