import { Input } from 'antd';
import PropTypes from 'prop-types';

const AntdInputPassword = ({ size, ...props }) => {
  return (
    <Input.Password
      {...props}
      size={size === 'xLarge' ? 'large' : size}
      style={{
        fontSize: '16px',
        color: ' #666666',
        borderRadius: 0,
        ...(size === 'xLarge'
          ? {
              padding: '11.5px 20px',
            }
          : {}),
      }}
    />
  );
};

AntdInputPassword.propTypes = {
  size: PropTypes.oneOf(['large', 'small', 'middle', 'xLarge']),
};

AntdInputPassword.defaultProps = {
  size: 'middle',
};

export default AntdInputPassword;
