import React, { useEffect, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
  InfoCircleOutlined,
  WarningFilled,
} from '@ant-design/icons';

const { confirmOverlay, confirmBox, error, info, warn, success } = styles;

const AccionaMessage = ({ message, onClose, delay, type }) => {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, delay);
  }, [delay, onClose]);

  const icon = useMemo(() => {
    switch (type) {
      case 'error':
        return <CloseCircleFilled />;
      case 'info':
        return <InfoCircleFilled />;
      case 'warn':
        return <WarningFilled />;
      case 'success':
        return <CheckCircleFilled />;
      default:
        <InfoCircleOutlined />;
    }
  }, [type]);

  const className = useMemo(() => {
    switch (type) {
      case 'error':
        return error;
      case 'warn':
        return warn;
      case 'success':
        return success;
      default:
        return info;
    }
  }, [type]);

  return (
    <div className={confirmOverlay}>
      <div className={confirmBox}>
        <p className={className}>
          {icon} {message}
        </p>
      </div>
    </div>
  );
};

AccionaMessage.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  delay: PropTypes.number,
  type: PropTypes.oneOf(['error', 'info', 'warn']),
};

AccionaMessage.defaultProps = {
  delay: 3000,
  type: 'error',
};
export default AccionaMessage;
