// import { cloneDeep } from 'lodash';
import * as TYPES from './types';

const initialState = {
  list: [],
  fetching: false,
  errorMessages: null,
  errorMessagesProfile: null,
  isCreated: false,
  total: 0,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.GET_USER_REQUEST:
    case TYPES.GET_USER_ME_REQUEST:
    case TYPES.GET_USERS_REQUEST:
    case TYPES.GET_ROLES_REQUEST:
    case TYPES.CREATE_USER_REQUEST:
    case TYPES.DELETE_USER_REQUEST:
    case TYPES.EDIT_USER_REQUEST:
    case TYPES.EDIT_USER_ME_REQUEST:
    case TYPES.UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        fetching: true,
        errorFetching: false,
        createUser: false,
      };

    case TYPES.GET_USER_ERROR:
    case TYPES.GET_USER_ME_ERROR:
    case TYPES.GET_USERS_ERROR:
    case TYPES.CREATE_USER_ERROR:
    case TYPES.DELETE_USER_ERROR:
    case TYPES.GET_ROLES_ERROR:
    case TYPES.EDIT_USER_ERROR:
    case TYPES.EDIT_USER_ME_ERROR:
    case TYPES.UPDATE_USER_PASSWORD_ERROR:
      return {
        ...state,
        errorMessages: payload,
        fetching: false,
      };

    case TYPES.GET_USERS_SUCCESS:
      return {
        ...state,
        total: payload.total,
        list: [...state.list, ...payload.users],
        fetching: false,
      };

    case TYPES.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: payload,
        fetching: false,
      };

    case TYPES.GET_USER_SUCCESS: {
      const list = [...state.list];
      let total = state.total;
      const index = list.findIndex((user) => user.id === payload.id);

      if (index !== -1) {
        list[index] = payload;
      } else {
        list.push(payload);
        list.sort((a, b) => a.id - b.id);
        total = total + 1;
      }
      return {
        ...state,
        total,
        list,
        fetching: false,
      };
    }

    case TYPES.CREATE_USER_SUCCESS: {
      /*       const list = [...state.list];
      let total = state.total;
      const index = list.findIndex((user) => user.id === payload.id);

      if (index !== -1) {
        list[index] = payload;
      } else {
        list.push(payload);
        list.sort((a, b) => a.id - b.id);
        total = total + 1;
      } */
      return {
        ...state,
        //total,
        //list,
        fetching: false,
        isCreated: true,
      };
    }

    case TYPES.EDIT_USER_SUCCESS:
    case TYPES.EDIT_USER_ME_SUCCESS: {
      const list = [...state.list];
      let total = state.total;
      const index = list.findIndex((user) => user.id === payload.id);

      if (index !== -1) {
        list[index] = payload;
      }

      return {
        ...state,
        total,
        list,
        fetching: false,
        isCreated: true,
      };
    }

    case TYPES.UPDATE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        fetching: false,
        isCreated: true,
      };
    }

    case TYPES.DELETE_USER_SUCCESS: {
      let list = [...state.list];
      list = list.filter(({ id }) => id !== payload.id);
      let total = state.total - 1;

      return {
        ...state,
        total,
        list,
        fetching: false,
      };
    }

    case TYPES.CLEAR_USERS_STATE: {
      return {
        ...state,
        fetching: false,
        errorMessages: null,
        errorMessagesProfile: null,
        isCreated: false,
        list: [],
      };
    }

    case TYPES.CREATE_ERRORS:
      return {
        ...state,
        errorMessages: payload,
        fetching: false,
      };

    case TYPES.CLEAR_ERRORS: {
      return { ...state, errorMessages: null };
    }

    case TYPES.CLEAR_CREATE_STATE: {
      return { ...state, isCreated: false, errorMessages: null };
    }

    case TYPES.INIT_REDUCER:
      return {
        ...initialState,
        list: state.list,
        errorMessages: null,
        errorMessagesProfile: null,
      };

    default:
      return { ...state };
  }
};

export default reducer;
