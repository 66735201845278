import { PropTypes } from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AccionaSimpleModal from '../../../../../components/AccionaSimpleModal';
import AccionaErrorModal from '../../../../../components/AccionaErrorModal';
import AccionaList from '../../../../../components/AccionaList';
import AccionaListTabs from '../../../../../components/AccionaListTabs';
import RemoveModal from '../../../../../components/RemoveModal';
import { ConnectionContext } from '../../../../../context/ConnectionContext/provider';
import { getFile } from '../../../../../services/api/files';
import { savePreDeliveryNoteOnline } from '../../../../../services/api/preDeliveryNote';
import * as OfflineActions from '../../../../../services/redux/ocr/preDNOffline/actions';
import * as OnlineActions from '../../../../../services/redux/ocr/preDNOnline/actions';
import { COLUMNS_HEADERS } from './columns';
import Details from './details';
import AccionaUploadModal from '../../../../../components/AccionaUploadModal';
import AccionaListButton from '../../../../../components/AccionaList/AccionaListButton';
import AccionaIcon from '../../../../../components/AccionaIcon';

const Tabs = {
  Online: 'Online',
  Offline: 'Offline',
};

const PreDNList = ({
  deleteOnline,
  deleteOffline,
  totalOfflineDocs,
  offlineDocs,
}) => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const [selectedTab, setSelectedTab] = useState(Tabs.Online);
  const { setLoading, online } = useContext(ConnectionContext);

  const [showAction, setShowAction] = useState(false);
  const onBack = () => window.history.back();
  const [removePDN, setRemovePDN] = useState(null);
  const [removePDNOffline, setRemovePDNOffline] = useState(null);
  const [selectedPDN, setSelectedPDN] = useState(null);
  const [previewIMG, setPreviwIMG] = useState(null);
  const [uploadOfflineError, setUploadOfflineError] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const getIMGOnline = (img_name) => {
    setLoading(true);
    getFile('delivery', img_name).then((response) => {
      setPreviwIMG(URL.createObjectURL(response.data));
      setLoading(false);
    });
  };

  const getIMGOffline = (img) => {
    setPreviwIMG(URL.createObjectURL(img));
  };

  useEffect(() => {
    if (!showAction) {
      document.querySelectorAll('.modalActions').forEach((element) => {
        element.style.setProperty('display', 'none');
      });
    }
  }, [showAction]);

  const uploadOffline = async (preDN) => {
    setLoading(true);
    try {
      await savePreDeliveryNoteOnline(preDN);
      deleteOffline(preDN.idCache);
    } catch (error) {
      setUploadOfflineError(error);
    }
    setLoading(false);
  };

  const onTabChange = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    document.addEventListener('click', function (event) {
      const target = event.target;
      if (
        typeof target?.className !== 'string' ||
        (!target?.className.includes('fa-cog') &&
          !target?.className.includes('btn'))
      ) {
        document.querySelectorAll('.modalActions').forEach((element) => {
          element.style.setProperty('display', 'none');
        });
      }
    });
  }, []);

  return (
    <div className="wrapper">
      <AccionaSimpleModal
        show={selectedPDN}
        onCancel={() => setSelectedPDN(null)}
        title={selectedPDN?.docket_number}
        content={<Details preRegisterDoc={selectedPDN} />}
      />
      <AccionaSimpleModal
        show={previewIMG}
        onCancel={() => setPreviwIMG(null)}
        content={
          <img
            id="region-select-canvas"
            alt="imgModal"
            src={previewIMG}
            width="100%"
          />
        }
      />

      <AccionaListTabs
        selectedTab={selectedTab}
        onBack={onBack}
        setSelectedTab={onTabChange}
        title={formatMessage({ id: 'pdn.table.title' })}
        tabs={[Tabs.Online, Tabs.Offline]}
        extraTitle={
          selectedTab === Tabs.Offline ? (
            <AccionaIcon
              disabled={totalOfflineDocs === 0 || online.state === false}
              id="header.viewSelect"
              className="btn btn-red"
              style={{ float: 'right' }}
              onClick={() => {
                setShowUploadModal(true);
              }}
              classIcon="icon fas fa-upload"
            />
          ) : undefined
        }
        render={() => {
          switch (selectedTab) {
            case Tabs.Online:
              return (
                <>
                  <RemoveModal
                    entity="preRegisteredDocuments"
                    msg={formatMessage({
                      id: 'delete.preRegisterDocument',
                    })}
                    onOk={() => {
                      deleteOnline(removePDN);
                      setRemovePDN(null);
                    }}
                    onCancel={() => setRemovePDN(null)}
                    hidden={!removePDN}
                  />

                  <AccionaList
                    pid={id}
                    key={Tabs.Online}
                    getItems={OnlineActions.getPreDNOnline}
                    headingWidth="150px"
                    entity="online"
                    columns={COLUMNS_HEADERS}
                    actions={(predn) => {
                      return (
                        <>
                          <AccionaListButton
                            id={`${predn.id}.viewSelect`}
                            onClick={() => {
                              getIMGOnline(predn.image_name);
                              setShowAction(false);
                            }}
                            type="view"
                          />
                          <AccionaListButton
                            id={`${predn.id}.preRegisterSelect`}
                            onClick={() => {
                              setSelectedPDN(predn);
                              setShowAction(false);
                            }}
                            type="details"
                          />{' '}
                          {predn.prm_data ? (
                            <div
                              style={{
                                color: '#ff0000',
                                background: '#fff1f0',
                                borderRadius: '2px',
                                border: '1px solid #ff0000',
                                fontSize: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '5px',
                              }}
                            >
                              {formatMessage({ id: 'prm' })}
                            </div>
                          ) : (
                            <>
                              <AccionaListButton
                                id={`${predn.id}.deleteSelect`}
                                onClick={() => {
                                  setRemovePDN(predn.id);
                                  setShowAction(false);
                                }}
                                type="delete"
                              />
                            </>
                          )}
                        </>
                      );
                    }}
                  />
                </>
              );
            case Tabs.Offline:
              return (
                <>
                  {showUploadModal && (
                    <AccionaUploadModal
                      onCancel={() => setShowUploadModal(false)}
                      show={showUploadModal}
                      cachedDeliveryNotes={offlineDocs}
                    />
                  )}
                  <RemoveModal
                    entity="preRegisteredDocuments"
                    msg={formatMessage({
                      id: 'delete.preRegisterDocument',
                    })}
                    onOk={() => {
                      deleteOffline(removePDNOffline);
                      setRemovePDNOffline(null);
                    }}
                    onCancel={() => setRemovePDNOffline(null)}
                    hidden={!removePDNOffline}
                  />
                  <AccionaErrorModal
                    onCancel={() => setUploadOfflineError(null)}
                    show={uploadOfflineError}
                    error={uploadOfflineError}
                  />

                  <AccionaList
                    pid={id}
                    key={Tabs.Offline}
                    headingWidth="150px"
                    getItems={OfflineActions.getPreDNOffline}
                    entity="offline"
                    columns={COLUMNS_HEADERS}
                    actions={(predn) => {
                      return (
                        <>
                          <AccionaListButton
                            id={`${predn.idCache}.viewSelect`}
                            disabled={!online.state}
                            onClick={() => {
                              uploadOffline(predn);
                              setShowAction(false);
                            }}
                            type="upload"
                          />
                          <AccionaListButton
                            id={`${predn.idCache}.viewSelect`}
                            onClick={() => {
                              getIMGOffline(predn.photo_element);
                              setShowAction(false);
                            }}
                            type="view"
                          />
                          <AccionaListButton
                            id={`${predn.idCache}.preRegisterSelect`}
                            onClick={() => {
                              setSelectedPDN(predn);
                              setShowAction(false);
                            }}
                            type="details"
                          />
                          <AccionaListButton
                            id={`${predn.idCache}.deleteSelect`}
                            onClick={() => {
                              setRemovePDNOffline(predn.idCache);
                              setShowAction(false);
                            }}
                            type="delete"
                          />
                        </>
                      );
                    }}
                  />
                </>
              );
            default:
              return null;
          }
        }}
      ></AccionaListTabs>
    </div>
  );
};
const mapStateToProps = ({ offline }) => ({
  totalOfflineDocs: offline.total,
  offlineDocs: offline.list,
});

const matchDispachToProps = {
  deleteOnline: OnlineActions.deletePreDNOnline,
  deleteOffline: OfflineActions.deletePreDNOffline,
};

PreDNList.propTypes = {
  deleteOnline: PropTypes.func.isRequired,
  deleteOffline: PropTypes.func.isRequired,
  totalOfflineDocs: PropTypes.number.isRequired,
  offlineDocs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, matchDispachToProps)(PreDNList);
