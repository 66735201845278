import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useOutletContext } from 'react-router-dom';
import AccionaButton from '../../../../../../../../../components/AccionaButton';
import AntdRangePicker from '../../../../../../../../../components/AntdRangePicker';
import { ConnectionContext } from '../../../../../../../../../context/ConnectionContext/provider';
import {
  downloadMixtureDataHistoricals,
  getMixtureDataHistoricals,
} from '../../../../../../../../../services/api/thermalControl/mixtures';
import { generateXlsxDownload } from '../../../../../../../../../utils/generateXlsxDownload';
import styles from './index.module.scss';

const { headerWrapper, wrapper, chartWrapper, icon } = styles;

const ChartWrapper = ({
  render,
  mixtureId,
  startTime,
  finished,
  endTime,
  mixtureName,
}) => {
  const noDataFoundTimeOut = useRef();
  const realTimeInterval = useRef();
  const { createError } = useContext(ConnectionContext);
  const [range, setRange] = useState([
    dayjs(startTime),
    finished ? dayjs(endTime) : dayjs(),
  ]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const { project } = useOutletContext();
  const intl = useIntl();

  const downloadXlsx = async () => {
    setDownloading(true);
    await downloadMixtureDataHistoricals({
      mixtureId,
      startDate: range[0].toISOString(),
      endDate: range[1].toISOString(),
      timeOffset: range[0].format('Z'),
    })
      .then((response) => {
        generateXlsxDownload(
          response.data,
          `${project.work_code}_${intl
            .formatMessage({
              id: 'mixture_type',
            })
            .toUpperCase()}_${mixtureName}_${range[0].format(
            'YYYYMMDD',
          )}_${range[1].format('YYYYMMDD')}.xls`,
        );
        setDownloading(false);
      })
      .catch((e) => {
        createError(e.response);
        setDownloading(false);
      });
  };

  const getData = useCallback(
    async (id) => {
      try {
        const { data } = await getMixtureDataHistoricals({
          mixtureId,
          startDate: range[0].toISOString(),
          endDate: range[1].toISOString(),
        });
        setData(data);
        setLoading(false);
      } catch (e) {
        if (e.response.status === 425) {
          if (noDataFoundTimeOut.current) {
            clearTimeout(noDataFoundTimeOut.current);
            noDataFoundTimeOut.current = undefined;
          }
          noDataFoundTimeOut.current = setTimeout(() => getData(id), 10000);
        } else {
          createError(e.response);
          setLoading(false);
        }
      }
    },
    [mixtureId, range, createError],
  );

  useEffect(() => {
    if (mixtureId) {
      setLoading(true);
      getData(mixtureId);
    }
  }, [mixtureId, getData, range, createError]);

  useEffect(() => {
    if (!finished) {
      realTimeInterval.current = setInterval(() => {
        getData(mixtureId);
      }, 60000);

      return () => {
        clearInterval(realTimeInterval.current);
      };
    }
  }, [mixtureId, getData, finished]);

  return (
    <div className={wrapper}>
      <div
        className={headerWrapper}
        style={{
          justifyContent: 'flex-end',
        }}
      >
        <div>
          <AntdRangePicker defaultValue={range} onChange={setRange} />
          <AccionaButton
            loading={downloading}
            classIcon={`fas fa-file-download ${icon}`}
            msg={intl.formatMessage({
              id: 'strength.thermalcontrol.mixture.view.download',
            })}
            type="default"
            onClick={downloadXlsx}
            style={{
              height: '27px',
              fontSize: '0.8em',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px 10px',
            }}
          />
        </div>
      </div>
      <div className={chartWrapper}>{render({ loading, data, range })}</div>
    </div>
  );
};

ChartWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  finished: PropTypes.bool.isRequired,
  mixtureId: PropTypes.number,
  mixtureName: PropTypes.string,
  availableChannels: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

ChartWrapper.defaultProps = {
  mixtureId: undefined,
  availableChannels: undefined,
  mixtureName: 'MEZCLA',
  startTime: undefined,
  endTime: undefined,
};
export default ChartWrapper;
